import React from 'react';

import { Image as BuilderImage } from '@builder.io/react';
import { ReadMoreButton as ErrorPageBtn } from '@glowforge/builder-components/lib/blog-articles-ref/blog-articles.styles';

import {
  ErrorPageContainer,
  PageContentWrapper,
  ImageWrapper,
  ErrorTitle,
  ErrorDescription,
  Linkwrapper,
} from '../../../../gf-styled/src/components/404-blog-page.styles';

const imageSrc = 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F2735d3d3f8194ef4a5fc8266904d7e6f';

const Blog404 = () => {
  return (
    <ErrorPageContainer>
      <PageContentWrapper>
        {/* in case the img is deleted from builder */}
        {imageSrc && (
          <ImageWrapper>
            <BuilderImage
              style={{
                height: '100%',
                width: '100%',
              }}
              lazy={false}
              image={imageSrc}
            />
          </ImageWrapper>
        )}
          <ErrorTitle as='h1'>
            Oops! This page got lost in the craft room clutter!
          </ErrorTitle>
          <ErrorDescription as='h2'>
            Looks like we've misplaced this page. It's probably off making
            something amazing! Unlike this page, your creativity is never lost.
            Why not head back to our blog and find your next inspiration?
          </ErrorDescription>
          <Linkwrapper href='/blog/main'>
            <ErrorPageBtn>Let's go!</ErrorPageBtn>
          </Linkwrapper>
      </PageContentWrapper>
    </ErrorPageContainer>
  );
};

Blog404.displayName = 'BlogErrorPage';

export default Blog404;