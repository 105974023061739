import styled from 'styled-components';
import {
    Title,
    Paragraph,
} from '@glowforge/gf-styled/src/components/typography/Typography';
import Link from '@glowforge/gf-styled/src/components/link';

const ErrorPageContainer = styled('div')`
  padding: 5rem 1rem;

  @media ${(props) => props.theme.queries.desktopDown} {
    padding: 4rem 1rem;
  }
`;

const PageContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const ImageWrapper = styled('div')`
  display: flex;
  max-width: 300px;
  margin: 0 auto;
  min-height: 230px;

  @media ${(props) => props.theme.queries.desktopDown} {
    max-width: 185px;
    min-height: 140px;
  }
`;

const ErrorTitle = styled(Title)`
  font-size: 36px;
  letter-spacing: -1.2px;
  text-align: center;

  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: 24px;
  }
`;

const ErrorDescription = styled(Paragraph)`
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  @media ${(props) => props.theme.queries.desktopDown} {
    font-size: 16px;
  }
`;

const Linkwrapper = styled(Link)`
  display: flex;
  justify-content: center;
`;


export {
    ErrorPageContainer,
    PageContentWrapper,
    ImageWrapper,
    ErrorTitle,
    ErrorDescription,
    Linkwrapper,
  };