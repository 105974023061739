/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Heading,
  Title,
} from '@glowforge/gf-styled/src/components/typography/Typography';
import Link from '@glowforge/gf-styled/src/components/link';
import COMMON from '../route-constants';

const ErrorPageContainer = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 1rem;

  /* When builder.io editing, want page to render same as published content */
  .builder-editing-page & {
    padding: 0;
    display: block;
  }

  .reload-button {
    display: inline-block;
    padding: 0;
  }
`;

const ErrorPageStatus = styled(Title)`
  display: block;
`;

const statusCodes = {
  400: 'Bad Request',
  403: 'Forbidden',
  // note, ./404.jsx
  404: "Sorry, we couldn't find this page.",
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
};

const errorMsgToStatus = {
  [COMMON.PAGE_ERRORS.PAGE_NOT_FOUND]: 404,
  [COMMON.PAGE_ERRORS.NOT_AUTHENTICATED]: 401,
  [COMMON.PAGE_ERRORS.FORBIDDEN]: 403,
};

const ErrorPage = ({ err, statusCode, title: propTitle }) => {
  const title =
    propTitle || statusCodes[statusCode] || 'Uh oh, something went wrong.';

  /**
   * Send client-side error information to New Relic
   */
  useEffect(() => {
    if (window.newrelic && window.newrelic.noticeError) {
      window.newrelic.noticeError(err, {
        errorType: 'Error Boundary',
      });
    }
  }, [err]);

  /**
   * Browswer refresh page onClick
   */
  const onClick = () => {
    window.location.reload();
  };

  /**
   * Use own ErrorPage implementation - Next.js Error page applies
   * unnecessary styles.
   */
  if (statusCode) {
    return (
      <ErrorPageContainer>
        {/* div to center content within flexbox */}
        <div>
          <ErrorPageStatus as='p'>{statusCode} </ErrorPageStatus>
          {title || statusCode ? <Heading as='h2'>{title}</Heading> : null}
          {/* <Link
            variant='body'
            href='https://statuspage.glowforge.com/'
            target='_blank'
            rel='noreferrer'>
            https://statuspage.glowforge.com/
          </Link> */}
        </div>
      </ErrorPageContainer>
    );
  }

  /**
   * Use a custom error template for client-side errors.
   * Next.js does not have a 'statusCode' for clientside errors.
   */
  return (
    <ErrorPageContainer className='ErrorPage'>
      <div>
        <p>
          Please
          <button
            type='button'
            className='button text reload-button'
            // eslint-disable-next-line react/jsx-no-bind
            onClick={onClick}>
            reload the page.
          </button>
        </p>
        <Link
          variant='body'
          href='https://statuspage.glowforge.com/'
          target='_blank'
          rel='noreferrer'>
          https://statuspage.glowforge.com/
        </Link>
      </div>
    </ErrorPageContainer>
  );
};

ErrorPage.displayName = 'ErrorPage';

ErrorPage.propTypes = {
  statusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.any]).isRequired,
  err: PropTypes.any,
  title: PropTypes.string,
};

ErrorPage.defaultProps = {
  title: null,
  err: null,
};

ErrorPage.getInitialProps = (ctx) => {
  const { res, err } = ctx;
  const errorType = (err && err.message) || '';
  let statusCode = res ? res.statusCode : null;

  // Set response status code if this is a known error type
  // todo: deprecate using errorMsg to render errors -> should use Next.js
  // https://nextjs.org/docs/api-reference/data-fetching/get-server-side-props#context-parameter
  if (res && errorMsgToStatus[errorType]) {
    statusCode = errorMsgToStatus[errorType];
    res.statusCode = statusCode;
  }

  return { err, statusCode };
};

export default ErrorPage;
