import React from 'react';
import { builder, Builder, BuilderComponent } from '@builder.io/react/lite';
import PropTypes from 'prop-types';
import registerComponents from '@glowforge/builder-components/lib/register';
import Error from './_error';
import ModalPriorityContext from '../context/ModalPriorityContext/ModalPriorityContext';
import { REACT_ROOT } from '../constants';
import builderComponents from '../util/builder/builder-components';
import { useRouter } from 'next/router';
import Blog404 from './blog/404';

builder.init('69fac0673d2644f0a0a962e1cb3045ef');
Builder.register('editor.settings', {
  customInsertMenu: true,
});

registerComponents(Builder, builderComponents);
export default function Custom404() {
  const router = useRouter();
  const isBlog = router.asPath.startsWith('/blog');
  const [showBuilder, setShowBuilder] = React.useState(false);

  React.useEffect(() => {
    if (Builder.isEditing || Builder.isPreviewing) {
      setTimeout(() => {
        setShowBuilder(true);
      }, 0);
    }
  }, []);

  if (showBuilder) {
    return (
      <BuilderComponent
        model={Builder.previewingModel || 'builder-page'}
        context={{
          // Used to coordinate builder.io modals with built-in modals
          ModalPriorityContext,
          REACT_ROOT,
        }}
        options={{
          includeRefs: true,
        }}
      />
    );
  }

  return  isBlog ? < Blog404 /> : <Error statusCode={404} />;
}

Custom404.propTypes = {
  blog404Data: PropTypes.object
};

Custom404.defaultProps = {
  blog404Data: {}
};